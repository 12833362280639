import { configureRefreshFetch } from "refresh-fetch";
import { AccessToken, OktaAuth, setRequestHeader } from "@okta/okta-auth-js";
import { getRequestPath, requestShouldBeSigned, appendAPIUrl } from "util/requestFiltering";

const { fetch: originalFetch } = window;

/**
 * Test if the request is a sandbox request by checking the headers
 * @param config
 */
const isSandboxRequest = (config?: RequestInit): boolean => {
  if (!config?.headers) {
    return false;
  }
  const headers = new Headers(config.headers);
  return headers.has("X-Sandbox-Request");
}

/**
 * Error raised in case authorization failures
 */
class FetchAuthorizationError extends Error {
  status = 401;
}

export const fetchOverride = function (authClient: OktaAuth) {
  // if 2 api urls are the same, we can consider sandbox to be disabled for that environment
  const isSandboxUnavailable = import.meta.env.VITE_API_URL === import.meta.env.VITE_SANDBOX_API_URL;

  return async (resource: RequestInfo | URL, config?: RequestInit) => {
    const token = await authClient.tokenManager.get("accessToken") as AccessToken;
    const shouldRequestBeSigned = typeof resource === "string" && requestShouldBeSigned(resource);

    if (!token || !shouldRequestBeSigned) {
      return originalFetch(resource, config);
    }

    const shouldCallSandboxApi = isSandboxRequest(config);
    const normalizedUrl = getRequestPath(resource as string);
    const urlToCall = appendAPIUrl(normalizedUrl, shouldCallSandboxApi);

    if (isSandboxUnavailable && shouldCallSandboxApi) {
      // if we are not in a sandbox environment, but the request is a sandbox request, log for development purposes
      console.log(`%c[Fake Sandbox Request]: ${urlToCall}`, "color: LightGreen");
    }

    return originalFetch(urlToCall, {
      ...config,
      headers: {
        ...config?.headers,
        Authorization: `Bearer ${token?.accessToken}`,
      },
    });
  };
}

export const zeosFetch = (authClient: OktaAuth) => {
  const defaultFetchCall = fetchOverride(authClient);
  return configureRefreshFetch({
    fetch: (url: RequestInfo | URL, options?: RequestInit) =>
      defaultFetchCall(url, options)
        .then(response => {
          if (!response.ok && response.status === 401) {
            const authorizationSource = response.headers.get(
              "X-Authorization-Source"
            );
            throw new FetchAuthorizationError(
              `401 status response, should refresh the token, X-Authorization-Source: ${authorizationSource}`
            );
          }
          return response;
        })
        .catch(error => Promise.reject(error)),
    shouldRefreshToken: (error: FetchAuthorizationError) => error?.status === 401,
    refreshToken: (): any => authClient.token.renewTokens(),
  });
};
