export const isBFFRequest = (url: string): boolean => {
  return url.startsWith(import.meta.env.VITE_BFF_URL);
}

export const isGatewayRequest = (url: string): boolean => {
  return url.startsWith('/api');
}
export const requestShouldBeSigned = (url: string): boolean => {
  return isBFFRequest(url) || isGatewayRequest(url);
}

export const getRequestPath = (url: string): string => {
  return url.replace('/api/', '/');
}

export const appendAPIUrl = (path: string, isSandboxRequest: boolean): string => {
  if (path.startsWith("http")) {
    return path;
  }

  const apiBase = isSandboxRequest ? import.meta.env.VITE_SANDBOX_API_URL : import.meta.env.VITE_API_URL;
  return `${apiBase}${path}`;
}
