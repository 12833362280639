import { Me } from "@zeos/platform";
import type { AppcuesConfig } from "../portal-config";
import { appcuesEnabled } from "../trackers/tracker-status";

export interface AppcuesObj {
  identify: (id: string) => void;
  page: () => void;
}

/**
 * Appends Appcues script to document head and loads it
 *
 * @param accountId Appcues Account ID
 *
 * @returns Promise<AppcuesObj|undefined> Appcues object created by script
 */
export const appendAppcuesScript = (
  accountId: AppcuesConfig["accountId"]
): Promise<AppcuesObj | undefined> =>
  new Promise((resolve, reject) => {
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.src = `//fast.appcues.com/${accountId}.js`;

    script.onload = () => {
      const appcues = (<any>window).Appcues as AppcuesObj;
      resolve(appcues || undefined);
    };

    script.onerror = e => {
      reject(e);
    };

    document.head.appendChild(script);
  });

/**
 * Identifies user for Appcues
 *
 * @param appcues Appcues object
 * @param userId User ID
 */
export const appcuesIdentiyUser = (
  appcues: AppcuesObj,
  userId: string
): void => {
  appcues.identify(userId);
};

/**
 * Tells Appcues that page has changed for first load
 * and subsequent dynamic routing
 *
 * @param appcues Appcues object
 */
export const appcuesPageView = (appcues: AppcuesObj): void => {
  // Initial navigation
  appcues.page();

  window.addEventListener("popstate", () => {
    appcues.page();
  });
};

/**
 * Starts Appcues
 *
 * This function checks if appcues is enabled, appends
 * Appcues script, and runs identification and page view
 *
 * @param config Appcues Configuration
 * @param me Me object
 */
export const startAppcues = async (
  config: AppcuesConfig,
  me: Me
): Promise<void> => {
  try {
    if (!(await appcuesEnabled(config))) {
      return;
    }
    const appcues = await appendAppcuesScript(config.accountId);

    if (appcues) {
      appcuesIdentiyUser(appcues, me.principal_user_id);
      appcuesPageView(appcues);
    }
  } catch (e) {
    console.warn("Appcues failed to start", e);
  }
};
