import { LifeCycles, ActivityFn, LifeCycleFn } from "single-spa";
import {
  AppConfig,
  AppError,
  AppType,
  PortalAppManifest,
  captureError,
  tracing,
} from "@zeos/platform";
import store from "core/store";
import {
  appBootstrappingStarted,
  appBootstrappingFinished,
  appLoadingFailed,
  appMountingStarted,
  appMountingFinished,
  appUnmountingStarted,
  appUnmountingFinished,
  appLoadingStarted
} from "core/store/app-status/app-status.actions";
import { decorateWithHooks } from "core/hooks-decorator";

import { loadAppManifest, isValidAppManifest } from "core/app-manifest";
import { getActiveAppName, isPortalLoadError } from "util/routing";
import { trace } from "core/monitoring/tracing";
import { reduxHook } from "./store/phase-hook";
import { getPortalLoadError } from "./portal-config";
import { createNavigationTree, NavigationTree } from "../util/navigation-tree";
import { unmountHook } from "./parcels";
import { getOverride } from "./importOverrides";

interface PortalApplication {
  manifest: PortalAppManifest;
  name: string;
  applicationOrLoadingFn(): Promise<LifeCycles>;
  activityFn: ActivityFn;
  label: string;
  type: AppType;
  pathPrefix: string;
}

/**
 * Decorate single-spa portal application lifeCycle functions
 * @param {string} appName
 * @param appLabel
 * @param {LifeCycles} lifeCycles
 */
function decorateApplicationLifeCycles<T>(
  appName: string,
  appLabel: string,
  lifeCycles: LifeCycles<T>,
) {
  return {
    ...lifeCycles,
    bootstrap: decorateWithHooks(
      "bootstrap_app",
      appName,
      lifeCycles.bootstrap as LifeCycleFn<T>,
      [
        reduxHook(
          store,
          appBootstrappingStarted(appName, appLabel),
          appBootstrappingFinished(appName, appLabel),
          (e: any) => appLoadingFailed(appName, appLabel, e)
        )
      ]
    ),
    mount: decorateWithHooks(
      "mount_app",
      appName,
      lifeCycles.mount as LifeCycleFn<T>,
      [
        reduxHook(
          store,
          appMountingStarted(appName, appLabel),
          appMountingFinished(appName, appLabel),
          (e: any) => appLoadingFailed(appName, appLabel, e)
        )
      ]
    ),
    unmount: decorateWithHooks(
      "unmount_app",
      appName,
      lifeCycles.unmount as LifeCycleFn<T>,
      [
        unmountHook,
        reduxHook(
          store,
          appUnmountingStarted(appName, appLabel),
          appUnmountingFinished(appName, appLabel),
          (e: any) => appLoadingFailed(appName, appLabel, e)
        )
      ]
    )
  };
}

function updateLoadingStatus(path: string, activeApp?: string) {
  window.LoadedPath = path;
  window.LoadedStatus = activeApp ? 200 : 404;
}

/**
 * Create a single spa portal application from app configuration
 * Tries to fetch app manifest from remote manifest location to know what js file
 * to "import" with the help of SystemJS
 *
 * @param {AppConfig} appConfig
 * @param {NavigationTree} navigationTree
 * @return {PortalApplication}
 */
export function createPortalApp(
  appConfig: AppConfig,
  navigationTree: NavigationTree
): PortalApplication {
  const { name, label, pathPrefix, type } = appConfig;

  const app = {
    name,
    label,
    type,
    pathPrefix,
    manifest: {} as PortalAppManifest,
    activityFn: (location: Location): boolean => {
      // Entry point on every transition of URL
      const activeApp = getActiveAppName(navigationTree, location);
      updateLoadingStatus(location.pathname, activeApp);

      if (isPortalLoadError(location)) {
        store.dispatch(
          appLoadingFailed("portal", "server", getPortalLoadError())
        );
      }
      return activeApp === name;
    },
    applicationOrLoadingFn: async () => {
      return trace.startActiveSpan("load_app", async (loadAppSpan) => {
        try {
          store.dispatch(appLoadingStarted(name, label));
          loadAppSpan.setAttributes({
            "first_load": true,
            "app_name": name,
          });
          const loadAppBundleSpan = trace.startSpan("load_mfe_bundle");
          let loadedModule: LifeCycles;

          // Internal try/catch block is necessary
          // because we want to attach the error message
          // to load_app_bundle span. This allows us
          // to collect load_app_bundle span when there is an error
          // and still retain the existing error flow
          try {
            const fetchManifestSpan = trace.startSpan("fetch_app_manifest", {}, tracing.getContextFromSpan(loadAppBundleSpan));
            const appManifest = await loadAppManifest(appConfig);
            fetchManifestSpan.setAttribute("http.url", appConfig?.url);
            if (!isValidAppManifest(appManifest)) {
              const manifestError = new Error(`invalid "${name}" app manifest file`);
              trace.setSpanError(fetchManifestSpan, undefined, manifestError);
              throw manifestError;
            }
            fetchManifestSpan.end();

            const fetchJsSpan = trace.startSpan("fetch_app_js", {}, tracing.getContextFromSpan(loadAppBundleSpan));
            // assigning app manifest for other usage (eg. i18next config)
            // achtung! do not reassign to avoid losing the reference that other might be using
            Object.assign(app.manifest, appManifest);
            const existingOverride =
              window?.importMapOverrides?.getOverrideMap?.()?.imports?.[
                appManifest.moduleName
                ];
            loadAppSpan.setAttribute("override_enabled", Boolean(existingOverride));
            const jsPath = existingOverride ?? appManifest?.files?.js;
            loadedModule = await window.System.import(jsPath);
            fetchJsSpan.setAttribute("http.url", jsPath);
            fetchJsSpan.end();
          } catch (error: any) {
            trace.setSpanError(loadAppBundleSpan, undefined, error);
            throw error;
          } finally {
            loadAppBundleSpan.end();
          }

          return decorateApplicationLifeCycles(name, label, loadedModule);
        } catch (error: any) {
          console.error(
            `Failed to load "${name}" app, caused by ${(error as Error).message}`
          );

          const isApplicationOverriden = Boolean(getOverride(name));
          if (!isApplicationOverriden) {
            // attach the error tag to span
            trace.setSpanError(loadAppSpan, undefined, error);
            captureError(error as Error, { tags: { severity: "critical" } });
          }
          store.dispatch(
            appLoadingFailed(
              name,
              label,
              new AppError(`Failed to load "${name}" application`, {})
            )
          );
          throw error;
        }
        finally {
          loadAppSpan.end();
        }
      })
    }
  };

  return app;
}

/**
 * Create single-spa portal applications given apps configurations
 *
 * @param {Array<AppConfig>} appsConfigs
 */
export function createPortalApps(
  appsConfigs: Array<AppConfig>
): Array<PortalApplication> {
  const navigationTree = createNavigationTree(appsConfigs);
  /**
   * TODO: once this ticket is merged: https://github.bus.zalan.do/merchant-platform/quasar-overview/issues/1420
   *       use getAppsWithPathPrefix from "util/apps" instead of `filter` to remove code redundancy
   */
  return appsConfigs
    .filter(app => Boolean(app.pathPrefix))
    .map(appConfig => createPortalApp(appConfig, navigationTree));
}
