import { LifeCycleFn, LifeCycles } from "single-spa";
import { decorateWithHooks } from "core/hooks-decorator";
import { unmountHook } from "./unmount-hook";

/**
 * Returns an empty promise in case application
 * is bootstrapped before
 *
 * @returns Promise
 */
async function emptyBootstrap() {
  return Promise.resolve();
}

export const decorateLifecycles = <T>(
  parcelName: string,
  lifeCycles: LifeCycles<T>,
  hasBeenBootstrapped?: boolean
): LifeCycles<T> => {
  return {
    bootstrap: decorateWithHooks(
      "bootstrap_parcel",
      parcelName,
      hasBeenBootstrapped
        ? emptyBootstrap
        : (lifeCycles.bootstrap as LifeCycleFn<T>),
    ),
    mount: decorateWithHooks(
      "mount_parcel",
      parcelName,
      lifeCycles.mount as LifeCycleFn<T>,
    ),
    update: decorateWithHooks(
      "update_parcel",
      parcelName,
      lifeCycles.update as LifeCycleFn<T>,
      []
    ),
    unmount: decorateWithHooks(
      "unmount_parcel",
      parcelName,
      lifeCycles.unmount as LifeCycleFn<T>,
      [unmountHook]
    )
  };
};
