import { OktaAuth } from "@okta/okta-auth-js";
import { appendAPIUrl } from "./requestFiltering";

/**
* Monkey patches the XMLHttpRequest open and send methods to intercept the requests and add the authorization header
* Necessary for the observability library which is using XHR instead of fetch
* @param authClient
*/
export const xhrOverride = (authClient: OktaAuth) => {
  const tracingEndpoints = [
    "/portals/fulfillment-b2b-portal/traces",
    "/portals/fulfillment-b2b-portal/metrics"
  ];

  /**
   * Appends gateway path to the tracing endpoint
   */
  (function(originalOpen: typeof XMLHttpRequest.prototype.open) {
    XMLHttpRequest.prototype.open = function (method: string, url: string, async?: boolean, user?: string | null, password?: string | null) {
      const isTracingEndpoint = tracingEndpoints.some(endpoint => url.includes(endpoint));
      if (!isTracingEndpoint) {
        return originalOpen.apply(this, [method, url, async, user, password] as any);
      }
      const gatewayUrl = appendAPIUrl(url, false);
      (this as any)._isIntercepted = true;
      return originalOpen.apply(this, [method, gatewayUrl, async, user, password] as any);
    };
  })(XMLHttpRequest.prototype.open);

  /**
   * Adds the authorization header to the request
   */
  (function(originalSend: typeof XMLHttpRequest.prototype.send) {
    XMLHttpRequest.prototype.send = function (body?: Document | XMLHttpRequestBodyInit | null) {
      if (!(this as any)._isIntercepted) {
        return originalSend.apply(this, [body]);
      }
      return authClient.getOrRenewAccessToken()
        .then((token) => {
          if (!token || (this as any)._isSigned) {
            return;
          }
          this.setRequestHeader("Authorization", `Bearer ${token}`);
          (this as any)._isSigned = true;
        })
        .finally(() => {
          return originalSend.apply(this, [body]);
        });
    };
  })(XMLHttpRequest.prototype.send);
}
