import i18n from "i18next"; // NOTE: The i18n should be initialized at time of use
import { Me, trackGA4Event } from "@zeos/platform";
import { UserProfileData } from "./types";
import { toggleConsentDataPreferences } from "../../core/trackers/consent";
import { navigateTo } from "../../util/routing";

const PAGE_CONTEXT = "ZEOS One shell app bar";
const COMPONENT_CONTEXT = "User Profile menu item";

export function getUserProfile(userData: Me): UserProfileData["userProfile"] {
  return {
    email: userData?.principal_user_email || "",
    name: userData?.display_name || "",
    role: userData?.role_display_name || ""
  };
}

export function getDefaultDropdownOptions(): UserProfileData["dropdownOptions"] {
  return [
    {
      iconName: "AvatarAIcon" as const,
      title: i18n.t("NAV_ITEMS.MY_PROFILE"),
      onOptionSelected: () => {
        trackGA4Event({
          category: "Button",
          action: "Clicked",
          label: "My Profile",
          pageContext: PAGE_CONTEXT,
          eventName: "MyProfileClick"
        });
        navigateTo("/my-profile");
      }
    },
    {
      iconName: "DoorOpenIcon" as const,
      title: i18n.t("NAVIGATION_BAR.USER_PROFILE.SIGN_OUT.LABEL"),
      onOptionSelected: () => {
        window.ga4DataLayer.push({
          event: "customGA4Event",
          event_name: "Click",
          label: "Button",
          value_text: i18n.t("NAVIGATION_BAR.USER_PROFILE.SIGN_OUT.LABEL"),
          component_context: COMPONENT_CONTEXT,
          page_context: PAGE_CONTEXT
        });
        setTimeout(() => {
          /**
           * Click handler on sign out icon to redirect to logout page
           */
          window.location.assign("/logout");
        }, 0);
      }
    }
  ];
}

export function getFooterOptions(
  consent: boolean,
  handleLegalClick: () => void
): UserProfileData["footerOptions"] {
  const footerOptions = [
    {
      title: i18n.t("NAVIGATION_BAR.USER_PROFILE.LEGAL_INFORMATION.LABEL"),
      onOptionSelected: () => {
        window.ga4DataLayer.push({
          event: "customGA4Event",
          event_name: "Click",
          label: "Button",
          value_text: i18n.t(
            "NAVIGATION_BAR.USER_PROFILE.LEGAL_INFORMATION.LABEL"
          ),
          component_context: COMPONENT_CONTEXT,
          page_context: PAGE_CONTEXT
        });
        handleLegalClick();
      }
    }
  ];

  if (consent) {
    const title = i18n.t("NAVIGATION_BAR.USER_PROFILE.DATA_PREFERENCES.LABEL");
    footerOptions.push({
      title,
      onOptionSelected: () => {
        window.ga4DataLayer.push({
          event: "customGA4Event",
          event_name: "Click",
          label: "Button",
          value_text: title,
          component_context: COMPONENT_CONTEXT,
          page_context: PAGE_CONTEXT
        });
        toggleConsentDataPreferences();
      }
    });
  }

  return footerOptions;
}
