import { ObservabilitySDK } from "@zalando/observability-sdk-react";
import { getPortalConfig } from "@zeos/platform";
import { tracingConsentEnabled } from "core/trackers/tracker-status";

export const initTracer = async () => {
  const portalConfig = getPortalConfig();
  const tracingConfig = portalConfig.monitoring?.tracing;

  if (!tracingConfig || !tracingConfig?.enabled) {
    return;
  }

  const isConsentGiven = await tracingConsentEnabled();

  if (!isConsentGiven) {
    return;
  }

  const sdk = new ObservabilitySDK({
    enabled: true,
    mode: "production",
    application: `zeos-one-shell/${portalConfig.environment}`,
    collectorUrl: "/portals/fulfillment-b2b-portal/traces",
    metricsUrl: "/portals/fulfillment-b2b-portal/metrics",
  });

  sdk.start();
}
