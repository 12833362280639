import { OktaAuth } from "@okta/okta-auth-js";
import { zeosFetch } from "util/fetchOverride";
import { xhrOverride } from "util/xhrOverride";

let isCallbackCalled = false;

export const initAuth = async (onLoggedIn: () => any) => {
  const authClient = new OktaAuth({
    issuer: import.meta.env.VITE_OKTA_ISSUER!,
    clientId: import.meta.env.VITE_OKTA_CLIENT_ID,
    redirectUri: window.location.origin + "/login/callback",
    scopes: ["openid", "profile", "email", "offline_access"],
  });
  window.okta = authClient;
  const currentPath = window.location.pathname;

  // Subscribe to authState change event.
  authClient.authStateManager.subscribe(function(authState) {
    // Logic based on authState is done here.
    if (!authState.isAuthenticated) {
      // render unathenticated view
      authClient.signInWithRedirect();
      return;
    }

    // expose zeos-specific fetch through both functions (temporary)
    // The idea is to replace the usage of window.fetch with zeosFetch
    // inside of @zeos/platform package
    // For now we will keep both as a part of backward compatibility
    window.zeosFetch = window.fetch = zeosFetch(authClient);
    xhrOverride(authClient);

    if (currentPath === "/logout") {
      authClient.signOut();
      return;
    }

    if (isCallbackCalled) {
      return;
    }
    // Render authenticated view
    isCallbackCalled = true;
    onLoggedIn?.();
  });

  if (currentPath && !["/login/callback", "/"].includes(currentPath) && !(await authClient.isAuthenticated())) {
    const redirectUrl = `${window.location.pathname}${window.location.search}`;
    sessionStorage.setItem("redirectUrl", redirectUrl);
  }

  // Handle callback
  if (authClient.token.isLoginRedirect()) {
    const { tokens } = await authClient.token.parseFromUrl(); // remember to "await" this async call
    authClient.tokenManager.setTokens(tokens);
  }

  // normal app startup
  authClient.start(); // will update auth state and call event listeners
}
