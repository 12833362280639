import React from "react";
import { useTranslation } from "react-i18next";
import {
  Box,
  Link,
  NavDrawer,
  VerticalMenu,
  Breadcrumbs
} from "@zeos/components";
import { Me } from "@zeos/platform";
import { navigateTo } from "../util/routing";
import {
  NavigationBarAppOption,
  NavigationBarNavOptionItem,
  NavigationBarNavOptions
} from "../core/app-config";
import NavBarAccount from "./NavBarAccount";
import { AlertDialog } from "./AlertDialog";
import { SnackbarList } from "./SnackbarList";
import { TopBar } from "./TopBar";
import { useBreadcrumbs } from "../hooks/useBreadcrumbs";
// eslint-disable-next-line import/no-unresolved, import/no-absolute-path
import ZeosLogo from "/assets/zeos/zeos.svg";
import { TFunction } from "i18next";

interface Props {
  me: Me | null;
  navigation: NavigationBarNavOptions;
  apps: NavigationBarAppOption[];
}

const findCurrentPrimaryNav = (
  tabs: NavigationBarNavOptions["tabs"],
  activeRoute: string
) =>
  tabs.find(({ children }) =>
    children?.some(({ link }) => activeRoute.startsWith(link))
  );

const findCurrentSecondaryNav = (
  tab: NavigationBarNavOptions["tabs"][number] | undefined,
  activeRoute: string
) => tab?.children?.find(({ link }) => activeRoute.startsWith(link));

const NAVBAR_OPEN_WIDTH = 248;

const createNavItem = (
  item: NavigationBarNavOptionItem,
  level: "primary" | "secondary",
  t: TFunction<"translation">,
  onClick?: () => void
) => {
  return {
    ...item,
    label: t(item.label),
    onClick:
      typeof onClick === "function" ? onClick : () => navigateTo(item.link),
    testId: `nav-item-${level}-${item.id}`
  };
};

const Dashboard = ({
  navigation,
  children,
  apps
}: React.PropsWithChildren<Props>): JSX.Element => {
  const { t } = useTranslation();
  const [activeRoute, setActiveRoute] = React.useState("");

  const currentNav = findCurrentPrimaryNav(navigation.tabs, activeRoute);
  const selectedNav = findCurrentSecondaryNav(currentNav, activeRoute);

  const breadcrumbs = useBreadcrumbs({ currentNav, selectedNav });

  const homeHref = React.useMemo(() => {
    const { tabs } = navigation;
    const tabLink = tabs?.[0]?.link;

    return tabLink ?? "/";
  }, [apps]);

  React.useEffect(() => {
    const handleRouteChange = () => {
      setActiveRoute(window.location.pathname);
    };

    handleRouteChange();
    window.addEventListener("popstate", handleRouteChange);

    return () => {
      window.removeEventListener("popstate", handleRouteChange);
    };
  }, []);

  const menuItems = React.useMemo(() => {
    return navigation.tabs.reduce(
      (menuItems, tab) => {
        const primaryChild = tab.children?.find(({ isPrimary }) => isPrimary);
        const secondaryChildren = tab.children?.filter(
          ({ isPrimary }) => !isPrimary
        );

        if (primaryChild) {
          menuItems.push({
            primary: createNavItem(primaryChild, "primary", t),
            secondary: primaryChild.children?.length
              ? primaryChild.children?.map(child =>
                  createNavItem(child, "secondary", t)
                )
              : undefined
          });
        }

        menuItems.push({
          primary: createNavItem(tab, "primary", t, () =>
            navigateTo(
              secondaryChildren?.length ? secondaryChildren[0].link : tab.link
            )
          ),
          secondary: secondaryChildren?.length
            ? secondaryChildren?.map(child =>
                createNavItem(child, "secondary", t)
              )
            : undefined
        });

        return menuItems;
      },
      [] as Parameters<typeof VerticalMenu>[0]["menuItems"]
    );
  }, [navigation.tabs]);

  return (
    <Box display="flex" flexDirection="row">
      <NavDrawer openWidth={NAVBAR_OPEN_WIDTH} open>
        <Link
          sx={{
            marginBottom: ({ spacing }) => spacing(5),
            padding: "0"
          }}
          onClick={() => navigateTo(homeHref)}
          underline="none"
        >
          <img src={ZeosLogo} alt="zeos" />
        </Link>
        <nav>
          <VerticalMenu
            menuItems={menuItems}
            selectedItemId={selectedNav?.id}
          />
        </nav>
      </NavDrawer>
      <AlertDialog />
      <SnackbarList />
      {/* TODO REMOVE MINWDITH ONCE RESPONSIVENESS IS IN PLACE */}
      <Box display="flex" flexDirection="column" flexGrow={1} minWidth={0}>
        <TopBar>
          <Breadcrumbs
            onClick={(
              link: string,
              event: React.MouseEvent<HTMLAnchorElement>
            ) => {
              event.preventDefault();
              navigateTo(link);
            }}
            items={breadcrumbs}
          />
          <NavBarAccount isAccountSelectAllowed={true} />
        </TopBar>
        <Box overflow="auto" maxWidth={`calc(100vw - ${NAVBAR_OPEN_WIDTH}px)`}>
          <Box flexGrow={1} overflow="auto" minWidth={1168}>
            {children}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Dashboard;
