import { tracing, type Toggle } from "@zeos/platform";
import { trace } from "core/monitoring/tracing";
import { appendImportMap } from "util/import-map";

const getConfiguration = () => {
  return Promise.all([
    tracing.spanFetchRequest(zeosFetch(`${import.meta.env.VITE_BFF_URL}/apps`)).then(response =>
      response.json()
    ),
    tracing.spanFetchRequest(zeosFetch(`${import.meta.env.VITE_BFF_URL}/navigation`)).then(response =>
      response.json()
    ),
    tracing.spanFetchRequest(zeosFetch(`${import.meta.env.VITE_BFF_URL}/parcels`)).then(response =>
      response.json()
    ),
    tracing.spanFetchRequest((span) =>
      zeosFetch('/api/portals/fulfillment-b2b-portal/enabled-feature-toggles', { headers: span.traceHeaders })
    ).then(response =>
      response.json()
    ),
  ]);
};

/**
 * Necessary to preload the environment configuration as it is used in the tracing initialization
 */
export async function loadEnvironmentConfig() {
  const localConfig = await import('./config');
  window.PortalConfig = {
    ...window.PortalConfig,
    ...localConfig.config,
  }
}

export async function loadConfiguration() {
  return trace.startActiveSpan("load_configuration", async (configTrace) => {
    return getConfiguration().then(async ([apps, navigation, parcels, toggles]) => {
      const enabledFeatureToggles = toggles.enabled_features as Toggle[];

      window.PortalConfig = {
        ...window.PortalConfig,
        apps,
        parcels,
        navigation,
        enabledFeatureToggles
      };
      appendImportMap([...apps, ...parcels]);
    }).finally(() => configTrace.end());
  });
}
