import React from "react";
import type { TFunction } from "i18next";
import { ErrorForbiddenIllustration } from "@zeos/illustrations/ErrorForbiddenIllustration";
import { ErrorGenericIllustration } from "@zeos/illustrations/ErrorGenericIllustration";
import { ErrorNotFoundIllustration } from "@zeos/illustrations/ErrorNotFoundIllustration";
import { EmptyStateIllustration } from "@zeos/illustrations/EmptyStateIllustration";
import { type AppError, trackGA4Event } from "@zeos/platform";

import { reloadTo } from "util/routing";
import { ErrorWrapperProps } from "../components/ErrorWrapper/ErrorWrapper";

const errorProps = ["title", "message", "code"] as const;
// maps error object's property to ErrorWrapper
// component's property
const propMap: Record<string, string> = {
  message: "description"
};

const getOverrides = (error: AppError) =>
  errorProps.reduce((acc, cur) => {
    if (error[cur]) {
      // If property exists in propMap, use the mapped prop name
      // as key. Otherwise, use the raw property name
      return { ...acc, [propMap[cur] || cur]: error[cur] };
    }

    return acc;
  }, {});

const getDefaultErrorProps = (
  error: AppError,
  t: TFunction
): ErrorWrapperProps => {
  switch (error.type) {
    /**
     * NotFound error occurs when the user tries to load
     * invalid URL.
     */
    case "NotFound":
      return {
        title: t("ERROR_PAGES.NOT_FOUND.TITLE"),
        description: t("ERROR_PAGES.NOT_FOUND.DESCRIPTION"),
        illustration: ErrorNotFoundIllustration,
        code: "Error 404",
        cta: {
          label: t("ERROR_PAGES.NOT_FOUND.BUTTON_TEXT"),
          onClick: () => {
            trackGA4Event({
              category: "Button",
              action: "Clicked",
              label: "Redirect Home",
              pageContext: "Error",
              eventName: "RedirectHomeClick"
            });
            reloadTo("/");
          }
        }
      };

    /**
     * Forbidden error occurs when the user is not authorized to access
     * the page.
     */
    case "Forbidden":
      return {
        title: t("ERROR_PAGES.FORBIDDEN.TITLE"),
        description: t("ERROR_PAGES.FORBIDDEN.DESCRIPTION"),
        code: "Error 403",
        illustration: ErrorForbiddenIllustration
      };

    /**
     * AuthenticationAccessDenied error occurs when user authentication flow
     * was disrupted due to the user does NOT have permission to access the application.
     */
    case "AuthenticationAccessDenied":
      return {
        title: t("ERROR_PAGES.AUTHENTICATION_ACCESS_DENIED.TITLE"),
        description: t("ERROR_PAGES.AUTHENTICATION_ACCESS_DENIED.DESCRIPTION"),
        illustration: ErrorForbiddenIllustration,
        code: "Error 403"
      };

    /**
     * Authentication error occurs when user authentication flow
     * was disrupted. In this case user can try to fix that error
     * using the CTA Button to reload to login page.
     */
    case "Authentication":
      return {
        title: t("ERROR_PAGES.AUTHENTICATION_ERROR.TITLE"),
        description: t("ERROR_PAGES.AUTHENTICATION_ERROR.DESCRIPTION"),
        illustration: ErrorGenericIllustration,
        code: "Error 401",
        cta: {
          label: t("ERROR_PAGES.AUTHENTICATION_ERROR.BUTTON_TEXT"),
          onClick: () => reloadTo("/login")
        }
      };

    /**
     * AuthenticationNonFixable error occurs when user authentication flow
     * was disrupted and the users can't fix these errors without
     * contacting support.
     */
    case "AuthenticationNonFixable":
      return {
        title: t("ERROR_PAGES.AUTHENTICATION_ERROR.TITLE"),
        description: t(
          "ERROR_PAGES.AUTHENTICATION_ERROR.DESCRIPTION_NON_FIXABLE"
        ),
        illustration: ErrorGenericIllustration,
        code: "Error 401"
      };

    default:
      /**
       * General error as a fallback to show to the user there is something
       * went wrong (unhandled error).
       */
      return {
        title: t("ERROR_PAGES.GENERAL_ERROR.TITLE"),
        description: t("ERROR_PAGES.GENERAL_ERROR.DESCRIPTION"),
        illustration: ErrorGenericIllustration,
        code: "Error 5xx",
        cta: {
          label: t("ERROR_PAGES.GENERAL_ERROR.BUTTON_TEXT"),
          onClick: () => reloadTo("/")
        }
      };
  }
};

export const getErrorWrapperProps = (
  error: AppError,
  t: TFunction
): ErrorWrapperProps => {
  // remove if in Q2
  if (/Failed to load "[^"]+" application/.test(error.message)) {
    return {
      title: "This page is under construction.",
      description: (
        <>
          This portal is currently in beta and we are diligently working to roll
          out this and additional features in the coming months.
          <br />
          <br />
          Your patience is appreciated, and we encourage you to stay tuned for
          further updates!
        </>
      ),
      illustration: EmptyStateIllustration
    };
  }

  return {
    ...getDefaultErrorProps(error, t),
    ...getOverrides(error)
  };
};
