import "systemjs";
import "systemjs/dist/extras/amd";
import "systemjs/dist/extras/named-register";
import "import-map-overrides";
import { sentry } from "@zeos/platform";
import { initTracer, trace } from "core/monitoring/tracing";
import { bootstrap } from "core/bootstrap";
import { initAuth } from "core/auth";
import { loadConfiguration, loadEnvironmentConfig } from "config-loader";

const loadingStartedTime = Date.now();

initAuth(async () => {
  await loadEnvironmentConfig();
  await initTracer();
  return trace.startActiveSpan("init_shell", { startTime: loadingStartedTime }, async (span) => {
    loadConfiguration()
      .then(() => bootstrap())
      .catch((error) => {
        trace.setSpanError(span, undefined, error);
        throw error;
      })
      .finally(() => span.end());
  });
}).catch(error => {
  console.error("Error initializing shell", error);
  sentry.captureError(error);
});
