import HomeIcon from "@zeos/icons/HomeIcon";
import FulfillmentIcon from "@zeos/icons/TruckIcon";
import DigitalReviewIcon from "@zeos/icons/ArticleReviewIcon";
import BoxIcon from "@zeos/icons/InventoryIcon";
import TshirtsIcon from "@zeos/icons/TShirtIcon";
import SettingsIcon from "@zeos/icons/SettingsIcon";
import DataIcon from "@zeos/icons/DataIcon";
import TechnicalIntegrationIcon from "@zeos/icons/IntegrationsIcon";
import UserIcon from "@zeos/icons/UserIcon";
import SubscriptionEventIcon from '@zeos/icons/NotificationsIcon'

export const appMenuIconMapping = {
  HomeIcon,
  FulfillmentIcon,
  DigitalReviewIcon,
  BoxIcon,
  TshirtsIcon,
  SettingsIcon,
  DataIcon,
  TechnicalIntegrationIcon,
  UserIcon,
  SubscriptionEventIcon
};
