import { AppConfig } from "@zeos/platform";
import { ImportMap } from "typings";

function setQueryParamImportMaps() {
  const urlParams = new URLSearchParams(window.location.search);
  const overrideModule = urlParams.get('override-module');
  const overrideSource = urlParams.get('override-source');

  if (!overrideModule || !overrideSource) {
    return;
  }
  window?.importMapOverrides?.addOverride(overrideModule, overrideSource);
  window?.importMapOverrides?.enableOverride(overrideModule);
}

export const appendImportMap = (apps: AppConfig[]) => {
  const imports = apps.reduce<ImportMap>(
    (accu, app) => ({
      ...accu,
      [app.name]: "http://localhost:3000/local-build/main.js?default"
    }),
    {}
  );

  setQueryParamImportMaps();

  const existingOverrideList = window?.importMapOverrides?.getOverrideMap(true);

  Object.entries(imports).forEach(([name, src]) => {
    const override = existingOverrideList?.imports?.[name];

    if (override) {
      return;
    }

    window?.importMapOverrides?.addOverride?.(name, src);
    if (!src || src.includes("?default")) {
      window?.importMapOverrides?.disableOverride?.(name);
    }
  });

  const tag = document.createElement("script");
  tag.type = "systemjs-importmap";
  tag.textContent = JSON.stringify({ imports });
  document.head.appendChild(tag);
};
