import React from "react";
import { Provider } from "react-redux";
import { TrackingContext } from "@zeos/components";

import store from "core/store";
import { getPortalConfig } from "core/portal-config";
import { ZeosThemeProvider } from "@zeos/theme";
import { getMe } from "@zeos/platform";
import Dashboard from "components/Dashboard";
import { getAllowedAppOptions } from "../../../util/app-options";
import { getAllowedNavOptions } from "../../../util/nav-options";
import { themeOverrides } from "src/template-system/theme";

/**
 * Root template
 *
 * Renders navigation bar and empty divs
 * for each application
 */
export const RootTemplate = (): React.ReactElement => {
  const { apps } = getPortalConfig();
  const appOptions = React.useMemo(() => getAllowedAppOptions(), []);
  const navOptions = React.useMemo(() => getAllowedNavOptions(), []);
  const me = getMe();

  return (
    <Provider store={store}>
      <ZeosThemeProvider theme={themeOverrides}>
        <TrackingContext>
          <Dashboard me={me} apps={appOptions} navigation={navOptions}>
            {apps.map(app => (
              <div id={app.name} data-testid={app.name} key={app.name} />
            ))}
          </Dashboard>
        </TrackingContext>
      </ZeosThemeProvider>
    </Provider>
  );
};
