import { fetch, AppError } from "@zeos/platform";
import { tracing, type Me } from "@zeos/platform";
import { type Span } from "core/monitoring/tracing";

const ME_ENDPOINT_TIMEOUT = 30000;

async function handleError<T>(res: Response, apiName: string): Promise<T> {
  if (res.ok) {
    return res.json() as Promise<T>;
  }

  const errorResponse = await res.json();
  throw new AppError(`Got ${res.status} for "${apiName}" api`, {
    type: "Generic",
    code: res.statusText,
    title: errorResponse.detail || errorResponse.title
  });
}

export const fetchMe = (parentSpan?: Span): Promise<Me> => {
  return tracing.spanFetchRequest((span) => fetch("/api/auth/me", {
    timeout: ME_ENDPOINT_TIMEOUT,
    headers: span.traceHeaders,
  }), parentSpan)
    .then(res => handleError<Me>(res, "me"))
    .then(me => {
      /**
       * Keycloak response doesn't contain this field,
       * so we cast it to a boolean false value
       *
       * TODO: remove this when we get rid of Keycloak as auth provider
       *  */
      me.has_multiple_accounts = !!me.has_multiple_accounts;

      return me;
    })
    .catch(e => {
      if ("code" in e) {
        throw e;
      } else {
        // Re-throw any error to get more informative error in sentry
        // with this error we would just get "failed to fetch"
        throw new Error(`Got error while "me" call: ${e.message}`);
      }
    });
};
